import React from "react";
import { SEO } from "~components/templates/seo";
import * as styles from "./index.module.scss";

const Component = () => {
  return (
    <>
      <SEO meta={[{ name: "robots", content: "noindex" }]} />
      <div className={styles.KenroTerms}>
        <div className={styles.KenroTerms_ArticleWrapper}>
          <h1 className={styles.KenroTerms_Title}>
            KENRO 無償トライアル 利用規約
          </h1>
          <p className={styles.KenroTerms_Intro}>
            「KENRO無償トライアル」（以下「本サービス」といいます。）は、株式会社Flatt
            Security（以下「当社」といいます。）が運営する、オンラインセキュリティトレーニングサービスです。この無償トライアル
            利用規約（以下「本規約」といいます。）は、本サービスを利用する利用者（以下「利用者」といいます。）に適用されます。利用者は、本規約に同意の上、本サービスを利用するものとします。
          </p>
        </div>
        <div className={styles.KenroTerms_ArticleWrapper}>
          <h2 className={styles.KenroTerms_ArticleTitle}>
            第 1 条 （本規約の適用範囲等）
          </h2>
          <div className={styles.KenroTerms_ArticleContent}>
            <ul>
              <li>
                本規約は、本サービスの利用条件及び当社と利用者との間の権利義務関係について定めています。本サービスに利用者登録した利用者は、本規約の定める条件に従って本サービスを利用できます。
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.KenroTerms_ArticleWrapper}>
          <h2 className={styles.KenroTerms_ArticleTitle}>
            第 2 条 （利用者登録）
          </h2>
          <div className={styles.KenroTerms_ArticleContent}>
            <ul>
              <li>
                本サービスの利用希望者は、本規約の内容に同意の上、当社所定の方法により利用申込を行うものとします。
              </li>
              <li>
                前項の利用申込を受けた場合、当社は、利用希望者について利用審査を実施し、利用希望者は、当社が利用を承認した時に本サービスの利用者となります。
              </li>
              <li>
                当社は、当社の裁量により利用希望者による本サービス利用の承認の可否を判断しますが、利用希望者が次の各号のいずれかに該当すると判断した場合には、利用者登録を取り消すことができるものとします。なお、当社はこれらの理由を利用希望者に対して開示する義務を負わないものとします。
                <br />
                (1)利用希望者が過去に利用取消、解除・退会処分を受けたことがある場合
                <br />
                (2)利用希望者が法人その他の団体又は事業者としての実体を有しない場合
                <br />
                (3)利用希望者が反社会的勢力等（第15条に定義します。）に該当する又は社会的に非難されるべき関係を有している場合
                <br />
                (4)その他利用希望者による本サービスの利用が不適当である又はそのおそれがある場合
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.KenroTerms_ArticleWrapper}>
          <h2 className={styles.KenroTerms_ArticleTitle}>
            第 3 条 （利用期間）
          </h2>
          <div className={styles.KenroTerms_ArticleContent}>
            <ul>
              <li>
                本サービスの利用期間は、当社と利用者との間で別途合意した期間とします。但し、当社は、本サービスの利用期間中、いつでも本サービスの提供を終了することができます。
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.KenroTerms_ArticleWrapper}>
          <h2 className={styles.KenroTerms_ArticleTitle}>
            第 4 条 （利用料等）
          </h2>
          <div className={styles.KenroTerms_ArticleContent}>
            <ul>
              <li>本サービスの利用料金は、無償とします。</li>
            </ul>
          </div>
        </div>
        <div className={styles.KenroTerms_ArticleWrapper}>
          <h2 className={styles.KenroTerms_ArticleTitle}>
            第 5 条 （利用環境の維持、ユーザーID・パスワードの管理）
          </h2>
          <div className={styles.KenroTerms_ArticleContent}>
            <ul>
              <li>
                本サービスの提供を受けるために必要な機器、通信手段等、当社所定の利用環境は全て利用者の費用と責任で設定及び維持するものとします。また、本サービスの利用にあたり必要となる通信費用は全て利用者の負担とし、当社所定の利用環境と異なる利用環境により利用者に生じた責任については、当社は一切の責任を負わないものとします。
              </li>
              <li>
                利用者はユーザーID（当社が本サービスの利用にあたって利用者に付与するIDをいいます。）及びパスワードを定期的に変更する、第三者に盗難されないようにする等の管理責任を負い、第三者への譲渡や貸与等をしてはなりません。
              </li>
              <li>
                利用者は、利用者のユーザーID及びパスワードが不正に第三者に使用された場合であっても、利用者が使用したものとみなして、当社に対し費用負担及び責任を負うものとします。
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.KenroTerms_ArticleWrapper}>
          <h2 className={styles.KenroTerms_ArticleTitle}>
            第 6 条 （利用者役職員の管理）
          </h2>
          <div className={styles.KenroTerms_ArticleContent}>
            <ul>
              <li>
                利用者のうち法人である利用者は、自己に所属する役員、従業員及び業務委託社員（以下これらを総称して「役職員」といいます。）に対し、本サービスを利用させることができるものとします。
              </li>
              <li>
                利用者のうち法人である利用者は、その役職員に対し本規約に規定する義務を遵守させるものとし、また、自己の費用と責任において、本サービスに関する役職員及びそのアカウントを適切に管理するものとします。役職員のアカウントの管理不十分、使用上の過誤、第三者の使用その他の役職員の行為については利用者の行為とみなし、これらによって生じた損害に関する当社及び第三者に対する責任は利用者が連帯して負うものとし、当社は一切の責任を負いません。
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.KenroTerms_ArticleWrapper}>
          <h2 className={styles.KenroTerms_ArticleTitle}>
            第 7 条 （権利帰属）
          </h2>
          <div className={styles.KenroTerms_ArticleContent}>
            <ul>
              <li>
                本サービスを構成するすべてのコンテンツ、プログラム、ソフトウェア、情報等（以下「当社コンテンツ」といいます。）に関する著作権、特許権、実用新案権、商標権、意匠権その他一切の知的財産権及びこれらの権利の登録を受ける権利、ノウハウその他の利益等（以下「知的財産権等」といいます。）は、当社又は当社への利用許諾の上当該知的財産権等を保有する第三者に帰属します。
              </li>
              <li>
                前項にかかわらず、本サービスに含まれるソースコードのうち、オープンソースライセンスに基づいて提供されているものに関しては、当該オープンソースライセンスに本規約の内容より優先されることが明示的に規定された条項がある場合、当該条項が優先的に適用されます。
              </li>
              <li>
                利用者は、方法又は形態の如何を問わず、本サービス上において当社又は第三者の知的財産権等を侵害する又はそのおそれのある行為を行ってはならないものとします。また、利用者は、知的財産権の存否にかかわらず、当社の事前の明示的な許諾がある場合を除いて、当社コンテンツについて、複製、配布、転載、転送、公衆送信、改変、翻案その他の二次利用等を行ってはならないものとします。
              </li>
              <li>
                利用者は、当社コンテンツを使用した学習教材の作成（教材内での直リンク、転載等の行為を含みます。）、提供及び販売を行ってはならないものとします。
              </li>
              <li>
                利用者が本条の規定に違反して問題が発生した場合、利用者は、自己の費用と責任において当該問題を解決するとともに、当社に何らの不利益、負担又は損害を与えないよう適切な措置を講じなければならないものとします。
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.KenroTerms_ArticleWrapper}>
          <h2 className={styles.KenroTerms_ArticleTitle}>
            第 8 条 （利用者情報等）
          </h2>
          <div className={styles.KenroTerms_ArticleContent}>
            <ul>
              <li>
                利用者は、本サービス上で提供する情報（以下「利用者情報」といいます。）を常に最新かつ正確に保つものとし、当該情報に変更があった場合には、直ちに当社所定の方法により当社に通知・連絡するものとします。
              </li>
              <li>
                当社は、以下に該当する又はそのおそれがあると判断したときは、利用者に対し必要な資料及び情報を提供するよう求めることができ、かつ本サービス上の利用者情報について、変更又は削除することができるものとします。この場合、利用者情報の変更又は削除により利用者又は第三者に損害が生じたとしても、当社は一切の責任を負いません。
                <br />
                (1) 利用者が第10条（禁止行為）に違反した場合
                <br />
                (2)
                利用者が当社に対して、当社所定の方法によらずに、利用者情報の変更又は削除を通知共有した場合
                <br />
                (3)
                その他本サービスの管理運用上必要な場合等、利用者情報を削除又は変更することが合理的である場合
              </li>
              <li>
                利用者は、当社が利用者情報につき統計的な分析をすること、当該分析結果に関する権利については当社に帰属し、利用者が必要な範囲内で自身の責任において内部利用できること、及び、当社が当該分析結果を特定の個人を識別しない形式で第三者に提供することすることがあることについて、予め同意するものとします。
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.KenroTerms_ArticleWrapper}>
          <h2 className={styles.KenroTerms_ArticleTitle}>
            第 9 条 （個人情報の取扱い）
          </h2>
          <div className={styles.KenroTerms_ArticleContent}>
            <ul>
              <li>
                当社は、利用者の個人情報については、当社が別途定めるプライバシーポリシー（
                <a href="https://flatt.tech/privacy-policy">
                  https://flatt.tech/privacy-policy
                </a>
                ）に則り、適正に取り扱うこととします。
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.KenroTerms_ArticleWrapper}>
          <h2 className={styles.KenroTerms_ArticleTitle}>
            第 10 条 （禁止行為）
          </h2>
          <div className={styles.KenroTerms_ArticleContent}>
            <ul>
              <li>
                利用者は、次の各号に定める行為をしてはなりません。
                <br />
                (1) 本規約及びこれに付随する諸規則に違反する行為
                <br />
                (2) 法令に違反する若しくは違反するおそれがある行為
                <br />
                (3) 公序良俗に反する行為又はそのおそれのある行為
                <br />
                (4)
                犯罪行為、これに結びつく若しくはこれを助長する行為又はそのおそれのある行為
                <br />
                (5)
                当社、他の利用者その他の第三者の知的財産権、プライバシーその他の権利利益を侵害し又はそのおそれのある行為
                <br />
                (6)
                方法・態様の如何を問わず、本サービス及び当社、他の利用者その他の第三者の名誉、信用を毀損し若しくはその業務及び運営を妨害する行為又はそのおそれのある行為
                <br />
                (7)
                第三者になりすますその他事実に反する又はそのおそれのある情報を提供する行為
                <br />
                (8) ユーザーID及びパスワードを不正に使用する行為
                <br />
                (9)
                自己又は他の利用者のユーザーID及びパスワードその他本サービスの情報等を第三者に提供する行為
                <br />
                (10)
                リバースエンジニアリング、当社のシステムへの不正アクセス、それに伴うプログラムコードの改ざん、コンピューターウィルスの頒布、本サービスのネットワークまたはシステム等への過度な負荷、その他本サービスの正常な運営を妨げる行為又はそのおそれのある行為
                <br />
                (11)
                本サービスの利用権限その他本サービス上の地位及び権利義務を、第三者に譲渡、担保提供その他の処分又は貸与する行為
                <br />
                (12)
                当社の事前の承諾なく、本サービス上において営業、宣伝、広告、勧誘等をする行為
                <br />
                (13)
                本サービス内外を問わず、当社及び本サービスに関して反社会的勢力を関与させる一切の行為
                <br />
                (14) その他当社が不適当と判断する行為
              </li>
              <li>
                当社は、利用者の行為が前項各号のいずれかに該当すると判断した場合、事前に通知することなく、以下の各号の措置の全部又は一部を講じることができるものとします。
                <br />
                (1) 本サービスの利用制限
                <br />
                (2) 本規約その他利用者と当社の間の契約の解除による退会処分
                <br />
                (3) その他当社が必要と判断する行為
              </li>
              <li>
                前項の措置により利用者に生じた損害について、当社は一切の責任を負わないものとします。
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.KenroTerms_ArticleWrapper}>
          <h2 className={styles.KenroTerms_ArticleTitle}>
            第 11 条 （損害賠償）
          </h2>
          <div className={styles.KenroTerms_ArticleContent}>
            <ul>
              <li>
                利用者は、本規約の違反又は本サービスの利用に関連して当社に損害を与えた場合、当社に発生した損害（逸失利益、間接損害及び弁護士費用その他の一切の費用を含みます。）を賠償するものとします。
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.KenroTerms_ArticleWrapper}>
          <h2 className={styles.KenroTerms_ArticleTitle}>
            第 12 条 （解除等）
          </h2>
          <div className={styles.KenroTerms_ArticleContent}>
            <ul>
              <li>
                当社は、利用者が次の各号のいずれかに該当する場合、何らの通知等を要することなく即時に、本規約その他利用者と当社の間の契約を解除することができるものとします。
                <br />
                (1) 登録情報に虚偽の情報が含まれている場合
                <br />
                (2) 利用者が禁止行為その他本規約等に違反する行為を行った場合
                <br />
                (3) 当社からの要請に対し誠実に対応しない場合
              </li>
              <li>
                その他当社が不適当と判断した場合第1項の措置により退会した利用者は、第1項各号の事由に該当しないことを証明した場合を除きこれに異議を申し立てることができません。
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.KenroTerms_ArticleWrapper}>
          <h2 className={styles.KenroTerms_ArticleTitle}>
            第 13 条 （本サービスの変更等、一時停止）
          </h2>
          <div className={styles.KenroTerms_ArticleContent}>
            <ul>
              <li>
                当社は、利用者に事前に通知することなく、本サービスの内容の全部若しくは一部を変更・追加し、又は終了することができるものとします。なお、当該変更又は追加によって、変更又は追加前の本サービスのすべての機能・性能が維持されることを保証するものではありません。
              </li>
              <li>
                当社は、以下のいずれかに該当すると当社が判断した場合、本サービスの提供の全部又は一部を停止又は中断する場合があります。
                <br />
                (1)
                本サービスに係るコンピューター・システムの点検又は保守作業を定期的又は緊急に行う場合
                <br />
                (2) コンピューター、通信回線等が事故により停止した場合
                <br />
                (3)
                火災、停電、疫病、天災地変等の不可抗力により本サービスの運営ができなくなった場合
                <br />
                (4)
                当社が利用するサービス事業者の事情により本サービスの提供が不可能又は困難となった場合
                <br />
                (5)
                その他、当社が本サービスの停止又は中断を必要と合理的に判断した場合
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.KenroTerms_ArticleWrapper}>
          <h2 className={styles.KenroTerms_ArticleTitle}>
            第 14 条 （免責等）
          </h2>
          <div className={styles.KenroTerms_ArticleContent}>
            <ul>
              <li>
                利用者は、法令の範囲内で本サービスをご利用ください。当社は、利用者が本サービスの利用に関連して、当該利用者の法令等及び外国の法令の違反が生じた場合であっても、一切の責任を負いません。
              </li>
              <li>
                当社は、本サービスの利用によって利用者間又は利用者と第三者との間で生じた紛争には一切の責任を負わないものとし、当該紛争については、当該利用者が自らの費用と負担において解決するものとします。
              </li>
              <li>
                当社は、本サービスの内容及び本サービスの利用によって得る情報の完全性、正確性、有用性が保たれるよう努めますが、当該完全性等について保証責任を負うものではありません。また、これらの結果に起因して利用者に生じた一切の損害等に対して、何らの責任も負いません。
              </li>
              <li>
                当社は、天災地変、自然災害、戦争、内乱、感染症の流行、ストライキ、通商停止、通信回線の利用不能及び事件事故等によるデータの消失その他の不可抗力により本規約その他利用者と当社の間の契約の全部又は一部に不履行が発生した場合、一切の責任を負わないものとします。
              </li>
              <li>
                当社は、経営上の理由その他やむを得ない事由が発生したことにより本サービスを中止又は終了する場合には、免責されるものとします。
              </li>
              <li>
                当社は、前各項に定めるほか、当社の故意又は重大な過失による場合を除き、利用者に発生した損害に対し、いかなる責任も負わないものとします。
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.KenroTerms_ArticleWrapper}>
          <h2 className={styles.KenroTerms_ArticleTitle}>
            第 15 条 （反社会的勢力の排除）
          </h2>
          <div className={styles.KenroTerms_ArticleContent}>
            <ul>
              <li>
                利用者及び当社は、現在、暴力団、暴力団員、暴力団員でなくなった時から5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロ又は特殊知能暴力集団等、その他これらに準ずる者（以下「反社会的勢力」といいます。）に該当しないこと、及び次の各号のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを保証します。
                <br />
                (1) 暴力団員等が経営を支配していると認められる関係を有すること
                <br />
                (2)
                暴力団員等が経営に実質的に関与していると認められる関係を有すること
                <br />
                (3)
                自己、自社若しくは第三者の不正の利益を図る目的又は第三者に損害を加える目的をもってする等、不当に暴力団員等を利用していると認められる関係を有すること
                <br />
                (4)
                暴力団員等に対して資金等を提供し、又は便宜を供与する等の関与をしていると認められる関係を有すること
                <br />
                (5)
                役員又は経営に実質的に関与している者が暴力団員等と社会的に非難されるべき関係を有すること
              </li>
              <li>
                利用者及び当社は、自ら又は第三者を利用して次の各号のいずれにも該当する行為を行わないことを確約します。
                <br />
                (1) 暴力的な要求行為
                <br />
                (2) 法的な責任を超えた不当な要求行為
                <br />
                (3) 取引に関して、脅迫的な言動をし、又は暴力を用いる行為
                <br />
                (4)
                風説を流布し、偽計を用い又は威力を用いて相手方の信用を毀損し、又は相手方の業務を妨害する行為
                <br />
                (5) その他前各号に準ずる行為
              </li>
              <li>
                利用者又は当社が、暴力団員等若しくは第1項各号のいずれかに該当し、若しくは前項各号のいずれかに該当する行為をし、又は第1項の規定にもとづく表明・保証に関して虚偽の申告をしたことが判明した場合には、自己の責に帰すべき事由の有無を問わず、相手方に対して何らの催告をすることなく本規約その他利用者と当社の間の契約を解除することができるものとします。
              </li>

              <li>
                利用者及び当社は、前項により本規約その他利用者と当社の間の契約を解除した場合には、相手方に損害が生じたとしてもこれを一切賠償する責任はないことを確認し、これを了承します。
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.KenroTerms_ArticleWrapper}>
          <h2 className={styles.KenroTerms_ArticleTitle}>
            第 16 条 （通知・連絡）
          </h2>
          <div className={styles.KenroTerms_ArticleContent}>
            <ul>
              <li>
                本サービスに関するお問い合わせその他利用者から当社に対する連絡又は通知、及び本規約の変更に関する通知その他当社から利用者に対する連絡又は通知は、当社ウェブページ上での公表、利用者が届け出た電子メールアドレスへの送信その他当社所定の方法で行うものとします。通知は、当社からの発信によってその効力が生ずるものとします。
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.KenroTerms_ArticleWrapper}>
          <h2 className={styles.KenroTerms_ArticleTitle}>
            第 17 条 （本規約の改定・変更）
          </h2>
          <div className={styles.KenroTerms_ArticleContent}>
            <ul>
              <li>
                当社は、以下の各号のいずれかに該当する場合は、民法第548条の4の規定に基づき本規約を随時変更できるものとします。本規約が変更された後は、変更後の本規約が適用されるものとします。
                <br />
                (1) 本規約の変更が、利用者の一般の利益に適合するとき
                <br />
                (2)
                本規約の変更が、契約をした目的に反せず、かつ、変更の必要性、変更後の内容の相当性及びその内容その他の変更に係る事情に照らして合理的なものであるとき
              </li>
              <li>
                当社は、本規約の変更を行う場合は、変更後の本規約の効力発生時期を定め、効力発生時期の前に、変更後の本規約の内容及び効力発生時期を、利用者への通知、本サービス上への表示その他当社所定の方法により利用者に周知するものとします。
              </li>
              <li>
                前二項の規定にかかわらず、前項の本規約の変更の周知後に利用者が本サービスを利用した場合又は当社所定の期間内に利用者が解約の手続を取らなかった場合、当該利用者は本規約の変更に同意したものとします。
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.KenroTerms_ArticleWrapper}>
          <h2 className={styles.KenroTerms_ArticleTitle}>
            第 18 条 （分離可能性）
          </h2>
          <div className={styles.KenroTerms_ArticleContent}>
            <ul>
              <li>
                本規約のいずれかの条項又はその一部が無効又は執行不能と判断された場合であっても、当該判断は他の部分に影響を及ぼさず、本規約の残りの部分は、引き続き有効かつ執行力を有するものとします。当社及び利用者は、当該無効若しくは執行不能とされた条項又は部分の趣旨に従い、これと同等の効果を確保できるように努めるとともに修正された本規約に拘束されることに同意するものとします。
              </li>
              <li>
                本規約のいずれかの条項又はその一部が、ある利用者との関係で無効又は執行不能と判断された場合であっても、他の利用者との関係における有効性等には影響を及ぼさないものとします。
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.KenroTerms_ArticleWrapper}>
          <h2 className={styles.KenroTerms_ArticleTitle}>第 19 条 （存続）</h2>
          <div className={styles.KenroTerms_ArticleContent}>
            <ul>
              <li>
                当社と利用者との間で本規約上の関係が終了した場合であっても、第7条、第8条第2項、第9条、第10条第3項、第11条、第12条第2項、第14条から第16条及び第18条から第21条までの規定は、引き続きその効力が存続するものとします。
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.KenroTerms_ArticleWrapper}>
          <h2 className={styles.KenroTerms_ArticleTitle}>
            第 20 条 （準拠法及び管轄）
          </h2>
          <div className={styles.KenroTerms_ArticleContent}>
            <ul>
              <li>
                本規約の準拠法は日本法とし、本規約に起因し又は関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.KenroTerms_ArticleWrapper}>
          <h2 className={styles.KenroTerms_ArticleTitle}>
            第 21 条 （協議解決）
          </h2>
          <div className={styles.KenroTerms_ArticleContent}>
            <ul>
              <li>
                当社及び利用者は、本規約に定めのない事項又は本規約の解釈に疑義が生じた場合には、互いに信義誠実の原則に従って協議の上速やかに解決を図るものとします。
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.KenroTerms_ArticleWrapper}>
          <h2 className={styles.KenroTerms_ArticleTitle}>附則</h2>
          <p> 2021年12月23日{` `}制定・施行</p>
        </div>
      </div>
    </>
  );
};

export default Component;
